
export default {
  email: 'Email',
  password: 'Password',
  password_confirm: 'Confirm password',
  new_password: 'New password',
  new_password_confirm: 'Confirm new password',
  logout: 'Log out',
  first_name: 'Fisrt name',
  last_name: 'Last name',
  identify_document: 'Document number',
  gender: 'Gender',
  department: 'Department/Area',
  employment: 'Job title',
  academic_degree: 'Level of education',
  admission: 'Start date',
  age_range: 'Age range',
  antiquity_range: 'Seniority range',
  job_type: 'Type of contract',
  jobType: 'Type of contract',
  charge: 'Position level',
  country: 'Country',
  headquarter: 'Location',
  phone_number: 'Phone number',
  buy: 'Buy',
  accept: 'Accept',
  cancel: 'Cancel',
  confirm: 'Confirm',
  save: 'Save',
  next: 'Next',
  back: 'Back',
  enable: 'Enable',
  disable: 'Disable',
  trash: 'Delete',
  import: 'Import',
  load: 'Upload',
  file: 'File',
  see: 'See',
  save_changes: 'Save changes',
  error_fix: 'Fix errors',
  confirm_changes: 'Confirm changes',
  back_home: 'Back to Home',
  back_list: 'Back to list',
  name: 'Name',
  sector: 'Industry',
  address: 'Address',
  enterprise: 'Company',
  enterprises: 'Companies',
  enterprise_size: 'Company size',
  language: 'Language',
  employees_number: 'Number of employees',
  demographic_cuts: 'Demographic cuts',
  license: 'License',
  duration: 'Duration',
  start_date: 'Start date',
  edit: 'Edit',
  edit_questions: 'Edit questionnaire',
  details: 'Details',
  view_details: 'See details',
  birthdate: 'Date of birth',
  confirm_save: 'Confirm and save',
  continue: 'Continue',
  start_poll: 'Start assessment',
  finish: 'Finish',
  back_to_dashboard: 'Back to dashboard',
  select_image: 'Select company logo',
  continue_filling: 'Continue filling',
  languages: 'Languages',
  begin: 'Start',
  answer: 'Answer',
  resend: 'Resubmit',
  view_pdf: 'Go to report',
  extend_plan: 'Extend plan',
  enterprise_name: 'Company name',
  customer: 'Client',
  pulses_qty_add: 'Amount of Pulse to add:',
  engagements_qty_add: 'Amount of Engagement to add:',
  admin_extension: 'Manage extension requests',
  reject: 'Reject',
  see_requests: 'See submitted requests',
  identify_type: 'Type of identity document',
  identify_type_s: 'ID',
  exist_identification: 'The entered identification number already exists.',
  newplan: 'New plan',
  admin_update: 'Manage update request',
  update_plan: 'Update plan',
  no_permissions: 'You do not have permissions to perform this action',
  want_request_extend: 'If you want to request an upgrade to your license, click the extend plan button.',
  group: 'Group',
  engagementsQty: 'Amount of Engagement',
  pulsesQty: 'Amount of Pulse',
  view_extension_history: 'View request history',
  plan_extend_info: 'Plan extension',
  demo_duration: '15 days',
  order: 'Order',
  required_field: 'Required field',
  valid_email: 'Enter valid email.',
  close: 'Close',
  invitation_url: 'Invitation link',
  url_copied: 'Link copied',
  invitation_url_copy: 'Copy link',
  copy_invitation_url: 'You can copy this link and send it to the collaborators who are participating in the assessment and who do not have an email or who have not received the invitation email. In order to answer the assessment, collaborators must enter their identity document number with which they have been uploaded to the platform.',
  send_invitation_url: 'Send invitation link',
  modal_title: 'Edit employee’s data',
  preview: 'Preview',
  close_poll: 'Close assessment',
  poll_delete_title: 'Close assessment',
  master_references_delete_poll: 'After closing the assessment, none of your collaborators will be able to participate in the assessment and this action cannot be undone in any way. All information for reports will be saved up to this point.',
  select_video: 'Select video for email',
  login_as_enterprise: 'Log in as this company',
  login_as_employee: 'Log in as this employee',
  add_token: 'Add OCC Tokens',
  enterprise_profile: 'Company profile',
  contact_information: 'ID - Contact information',
  complete_name: 'Full name',
  change: 'Change',
  actual_password: 'Current password',
  got_it: '¡ Understood !',
  password_change: 'Password change',
  actual_email: 'Current email',
  email_change: 'Change email',
  new_email: 'New email',
  view_profile: 'View profile',
  change_enterprise: 'Chane company',
  changing_enterprise: 'Cambio de empresa',
  fast_search: 'Fast search',
  sales_range: 'Sales range',
  manage_token: 'Manage OCC Tokens',
  select: 'Select',
  update: 'Update',
  see_more: 'See more',
  back_to_login: 'Back to login',
  authorizations: 'Authorizations',
  manage_authorizations: 'Manage Authorizations',
  back_to_list: 'Back to list',
  create: 'Create',
  create_account: 'Create Account',
  terms_header: 'Terms and conditions of use',
  assign_products: 'Product assignment',
  products: 'Products',
  api_host: 'API Host',
  api_port: 'API Port',
  web_host: 'Web Host',
  web_port: 'Web port',
  send: 'Send',
  send_email: 'Sens email',
  list: 'List',
  login_as_customer: 'Log in as this client',
  login_as: 'Log in as {name}',
  delet: 'Delete',
  go_back: 'Back',
  relation: 'Relationsgip',
  additionalDemographics1: 'Additional Demographics 1',
  additionalDemographics2: 'Additional Demographics 2',
  view_tutorial_video: 'View tutorial',
  invalid_file: 'The selected file is not valid'
}
